import React from 'react';

export default React.createContext({
  user: null,
  nearbyRoutes: [],
  currentRoute: '',
  currentPin: '',
  appStarted: false,
  isLoggedIn: false,
  toggleMySpeedDial: (toggle) => {},
  toggleMySpeedDialMenu: (menu) => {},
  toggleShowStartSplash: (toggle) => {},
  toggleLoggedIn: () => {},
  setUser: (user) => { return user },
  setNearbyRoutes: (routesArray) => { return routesArray },
  setCurrentRoute: (currentRoute) => { return currentRoute },
  setCurrentPin: (currentPin) => { return currentPin },
  setLiveGeo: (liveGeo) => { return liveGeo},
});