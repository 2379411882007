import length from '@turf/length';

const rawTracks = [
    require("../../assets/tracks/VN01_track.json"),
    require("../../assets/tracks/VN02_track.json"),
    require("../../assets/tracks/VN03_track.json"),
    require("../../assets/tracks/VN04_track.json"),
    require("../../assets/tracks/VN05_track.json"),
    require("../../assets/tracks/VN06_track.json"),
    require("../../assets/tracks/VN07_track.json"),
    require("../../assets/tracks/VN08_track.json"),
    require("../../assets/tracks/VN09_track.json"),
    require("../../assets/tracks/VN10_track.json"),
    require("../../assets/tracks/VN11_track.json"),
    require("../../assets/tracks/VN12_track.json"),
    require("../../assets/tracks/VN13_track.json"),
    require("../../assets/tracks/VN14_track.json"),
    require("../../assets/tracks/VN15_track.json"),
    require("../../assets/tracks/VN16_track.json"),

    require("../../assets/tracks/LT01_track.json"),
];

// console.log('rawTracks: ', rawTracks);
// console.log('rawTracks[0]: ', rawTracks[0]);

/*
let rawSplit = [];
console.time('rawTracks');
rawTracks.forEach((track) => {
  // console.log('track: ', track);
  // console.log('track.area: ', track.area);
  rawSplit = track.name.split('.');
  track.area = rawSplit[0];
  track.flag = rawSplit[1];
  // console.log('track.flag: ', track.flag);
  return track;
});
console.timeEnd('rawTracks');
*/

/*
const processTracks = function(rawTracks) {
  let rawSplit = [];
  rawTracks.forEach((track) => {
    // console.log('track: ', track);
    // console.log('track.area: ', track.area);
    rawSplit = track.name.split('.');
    track.area = rawSplit[0];
    track.flag = rawSplit[1];
    // console.log('track.flag: ', track.flag);
    return track;
  });
}
console.time('processTracks()');
const pT = processTracks(rawTracks);
console.timeEnd('processTracks()');
console.log('pT: ', pT);
*/




// const track = rawTracks[0].features[0].geometry.coordinates[0];
// const lat1 = track[0][1];
// const lng1 = track[0][0];
// const lat2 = track[1][1];
// const lng2 = track[1][0];
// console.log('LoadGeoJsonTracks rawTracks[0]: ', rawTracks[0]);


/* @turf Method */
/*
const turfLength = function(processedTrack) {
  // Source: https://turfjs.org/docs/#length
  const turfLength = length(processedTrack, {units: 'kilometers'});
  return turfLength;
};

console.time('turf');
rawTracks.forEach((track, i) => {
  if (i === 0) return false;
  const turfLn = turfLength(track);
  console.log('track.name: ', track.name);
  console.log('turfLn: ', turfLn);
  console.log('---------------------------');
});
console.timeEnd('turf');
*/


/* Pure Math Method */
const getDistanceBetweenPoints = function(lat1, lng1, lat2, lng2) {
  // Source: https://stackoverflow.com/questions/46723333/how-to-get-the-distance-between-two-locations-based-on-their-latitude-and-longit
  // This is 2-4 times faster than @turf library
  var radlat1 = Math.PI * lat1 / 180;
  var radlat2 = Math.PI * lat2 / 180;
  var theta = lng1 - lng2;
  var radTheta = Math.PI * theta/180;
  var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radTheta);
  dist = Math.acos(dist);
  dist = dist * 180/Math.PI * 60 * 1.1515 * 1.609344;
  return dist;
};

const getTrackLength = function (track) {
  let trackLength = 0;
  let currentDistance = 0;
  track.forEach((point, i) => {
    if (i === 0) return false;
    
    currentDistance = getDistanceBetweenPoints(track[i][1], track[i][0], track[i - 1][1], track[i - 1][0]);
    if (Number.isNaN(currentDistance)) {
      return false;
    };
    
    trackLength = trackLength + currentDistance;
  });

  return trackLength;
};

// console.time('gTD');
// const gTD = getTrackDistance(track);
// console.timeEnd('gTD');

let totalLength = 0;
const processTracksFull = function(rawTracks) {
  let rawSplit = [];
  rawTracks.forEach((track) => {
    // console.log('track: ', track);
    // console.log('track.area: ', track.area);
    rawSplit = track.name.split('.');
    track.area = rawSplit[0];
    track.flag = rawSplit[1];

    // Get the track length first mathematically
    track.trackLength = getTrackLength(track.features[0].geometry.coordinates[0]);
    
    // If this fails, use the (3x slower) turf plugin
    if (track.trackLength === 0) track.trackLength = length(track, {units: 'kilometers'});

    totalLength = totalLength + track.trackLength;
    // console.log('track: ', track);
    // console.log('--------------------------------');
    // return track;
  });

  rawTracks.totalLength = totalLength;
  return rawTracks;
}
// console.time('processTracksFull()');
const processedTracks = processTracksFull(rawTracks);
// console.timeEnd('processTracksFull()');



// console.log('LoadGeoJsonTracks getDistanceBetweenPoints processedTracks[0]: ', );
// console.log('LoadGeoJsonTracks getDistanceBetweenPoints pT: ', pT);
// console.log('LoadGeoJsonTracks processedTracks: ', processedTracks);
// console.log('LoadGeoJsonTracks getDistanceBetweenPoints processedTracks: ', processedTracks);
// console.log('LoadGeoJsonTracks getDistanceBetweenPoints gTD: ', gTD);
// console.log('LoadGeoJsonTracks turfLength processedTracks[0]: ', turfLn);

const centerMapLGT = {
  centerLat: rawTracks[0].features[0].geometry.coordinates[0][0][1],
  centerLng: rawTracks[0].features[0].geometry.coordinates[0][0][0],
}

export { processedTracks, centerMapLGT };