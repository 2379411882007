import Cached from "./Cached";
import { processedTracks } from './LoadGeoJsonTracks';


const findLastUpdatedArea = function(areas) {
    let invAreasValue = 0;
    let invRoutesValue = 0;
    let invLength = 0;
    let totalLength = 0;
    const firstItemToMatch = { invRoutes: { 
            invValue: 0,
            invLength: 0,
            totalLength: 0,
            lastEditTime: null,
            lastEditBy: null,
            lastInvArea: null,
            lastInvRoute: null,
            lastInvPin: null, 
        }
    };

    const stringifiedAreas = JSON.stringify(areas);
    const clonedAreas = JSON.parse(stringifiedAreas);
    
    const lastAreaUpdated = clonedAreas.reduce(function(prevValue, nextValue) {
        
        /*
        // TEMP
        if (nextValue.id === 'VRA') {
            // console.log('prevValue BEFORE: ', prevValue);
            // console.log('nextValue: ', nextValue);
        };
        */
        

        let result = firstItemToMatch;

        if (nextValue.invRoutes) {
            if (nextValue.invRoutes.lastEditTime) {
                // Count the inventoried areas length
                invLength = invLength + nextValue.invRoutes.invLength;

                // Count the inventoried areas
                invAreasValue++;

                // Check the inventaried routes
                if (nextValue.invRoutes.invValue) {
                    invRoutesValue = invRoutesValue + nextValue.invRoutes.invValue;
                };

                if (!prevValue || !prevValue.invRoutes || !prevValue.invRoutes.lastEditTime) {
                    prevValue = firstItemToMatch;
                };

                if (prevValue.invRoutes.lastEditTime > nextValue.invRoutes.lastEditTime) {
                    result = prevValue;
                } else {
                    result = nextValue;
                }
            };

            // Total tracks length counts all routes
            totalLength = totalLength + nextValue.invRoutes.totalLength;
        } else {
            result = prevValue || firstItemToMatch;
        };

        
        /*
        if (nextValue.id === 'VRA') {
            // console.log('prevValue AFTER: ', prevValue);
            // console.log('result: ', result);
        };
        */

        return result;
    }, firstItemToMatch);

    /*
    // TEMP
    if (lastAreaUpdated.id === 'VRA') {
        // console.log('lastAreaUpdated: ', lastAreaUpdated);
    };
    */
    

    if (!lastAreaUpdated || !lastAreaUpdated.invRoutes) {
        return firstItemToMatch;
    };

    lastAreaUpdated.invRoutes.totalLength = totalLength;
    lastAreaUpdated.invRoutes.invLength = invLength;
    lastAreaUpdated.invRoutes.invAreasValue = invAreasValue;
    lastAreaUpdated.invRoutes.invRoutesValue = invRoutesValue;
    lastAreaUpdated.invRoutes.lastInvArea = lastAreaUpdated.id;

    return lastAreaUpdated;
};

const findLastUpdatedRouteInArea = function(routes, areaId) {
    let invValue = 0;
    const firstItemToMatch = { invPins: { 
            invValue: 0,
            lastEditTime: null,
            lastEditBy: null,
            lastInvRoute: null, 
            lastInvPin: null, 
        }
    };

    const stringifiedRoutes = JSON.stringify(routes);
    const clonedRoutes = JSON.parse(stringifiedRoutes);
    
    const lastRouteUpdated = clonedRoutes.reduce(function(prevValue, nextValue) {
        /*
        // TEMP
        if (areaId === 'VRA') {
            // console.log('prevValue BEFORE: ', prevValue);
            // console.log('nextValue: ', nextValue);
        };
        */

        let result = firstItemToMatch;

        if (nextValue.invPins && nextValue.invPins.lastEditTime) {
            invValue++;

            if (!prevValue || !prevValue.invPins || !prevValue.invPins.lastEditTime) {
                prevValue = firstItemToMatch;
            };

            if (prevValue.invPins.lastEditTime > nextValue.invPins.lastEditTime) {
                result = prevValue;
            } else {
                result = nextValue;
            };
        } else {
            result = prevValue || firstItemToMatch;
        };
        
        /*
        if (areaId === 'VRA') {
            // console.log('prevValue AFTER: ', prevValue);
            // console.log('result: ', result);
        };
        */

        return result;
    }, firstItemToMatch);

    /*
    // TEMP
    if (areaId === 'VRA') {
        // console.log('lastRouteUpdated: ', lastRouteUpdated);
    };
    */

    if (!lastRouteUpdated || !lastRouteUpdated.invPins) {
        return firstItemToMatch;
    };

    lastRouteUpdated.invPins.invValue = invValue;
    lastRouteUpdated.invPins.lastInvArea = areaId;

    return lastRouteUpdated;
};

const findLastUpdatedPinInRoute = function(pins, routeId) {
    let invValue = 0;
    const firstItemToMatch = { 
        invValue: 0,
        lastEditTime: null,
        lastEditBy: null,
        lastInvPin: null, 
    }
    const filteredPinsByPinRoutes = pins.filter(pin => {
        return pin.routes.find((route => {
            return route === routeId;
        }));
    });

    /*
    // TEMP
    if (routeId === 'LT02.CG') {
        // console.log('routeId: ', routeId);
        // console.log('filteredPinsByPinRoutes: ', filteredPinsByPinRoutes);
    };
    */

    if (filteredPinsByPinRoutes.length <= 0) {
        return firstItemToMatch;
    };

    let result = firstItemToMatch;
    const stringifiedFilteredPinsByPinRoutes = JSON.stringify(filteredPinsByPinRoutes);
    const clonedFilteredPinsByPinRoutes = JSON.parse(stringifiedFilteredPinsByPinRoutes);

    const lastPinUpdated = clonedFilteredPinsByPinRoutes.reduce(function(prevValue, nextValue) {
        if (nextValue.lastEditTime && nextValue.lastEditTime.length > 0) {                
            invValue++;
            /*
            // TEMP
            if (routeId === 'LT02.CG') {
                // console.log('findLastUpdatedPinInRoute() nextValue: ', nextValue);
                // console.log('findLastUpdatedPinInRoute() invValue: ', invValue);
            };
            */
        };

        if (prevValue.lastEditTime > nextValue.lastEditTime) {
            result = prevValue;
        } else {
            result = nextValue;
        }
        
        /*
        // TEMP
        if (nextValue.lastEditTime === '1637597303561') {
            // console.log('1637597303561 prevValue: ', prevValue);
            // console.log('1637597303561 nextValue: ', nextValue);
            // console.log('1637597303561 result: ', result);
        };
        */
        return result;
    }, firstItemToMatch);

    /*
    // TEMP
    if (routeId === 'VN002TR') {
        // console.log('lastPinUpdated: ', lastPinUpdated);
    };
    */
    
    lastPinUpdated.invValue = invValue;
    lastPinUpdated.lastInvRoute = routeId;

    return lastPinUpdated;
};

const processRoutes = (inventory, sourceRoutes) => {
    /* Routes */
    sourceRoutes && sourceRoutes.forEach(route => {
        // Find corresponding track to get route length
        
        const foundMatchingAreaIndex = inventory.areas.findIndex(area => area.id === route.areaFlat.id);
        // console.log('route: ', route);
        
        if (foundMatchingAreaIndex >= 0) {
            const currentTrack = findTrackByCode(route.code);
            if (currentTrack) {
                route.trackLength = currentTrack.trackLength;
                // console.log('currentTrack.trackLength: ', currentTrack.trackLength);
            } else {
                route.trackLength = 0;
            };
            // console.log('inventory.areas[foundMatchingAreaIndex].invRoutes: ', inventory.areas[foundMatchingAreaIndex].invRoutes);


            if (!inventory.areas[foundMatchingAreaIndex].invRoutes) {
                inventory.areas[foundMatchingAreaIndex].invRoutes = {
                    invValue: 0,
                    totalLength: 0,
                    invLength: 0,
                    lastEditTime: '',
                    lastEditBy: null,
                    lastInvRoute: null,
                    lastInvPin: null,
                };
            };

            if (!inventory.areas[foundMatchingAreaIndex].routes) {
                // Area was not spiced-up with routes + extra fields
                inventory.areas[foundMatchingAreaIndex].routes = [];
            };
            
            // console.log('BEFORE inventory.areas[foundMatchingAreaIndex].invRoutes.invLength: ', inventory.areas[foundMatchingAreaIndex].invRoutes.invLength);
            // console.log('BEFORE route: ', route);
            // console.log('BEFORE route.pins: ', route.pins);
            // console.log('BEFORE route.pins.length: ', route.pins ? route.pins.length : 'undefined');
            // console.log('route', route.code);
            // console.log('inventory.areas[foundMatchingAreaIndex].invRoutes.invLength BEFORE: ', inventory.areas[foundMatchingAreaIndex].invRoutes.invLength);
            
            // Measure all routes for each area
            inventory.areas[foundMatchingAreaIndex].invRoutes.totalLength = inventory.areas[foundMatchingAreaIndex].invRoutes.totalLength + route.trackLength;
            
            inventory.areas[foundMatchingAreaIndex].routes.push(route);
            // console.log('Final route: ', route);
            // console.log('route.trackLength: ', route.trackLength);
            // console.log('Final inventory.areas[foundMatchingAreaIndex].invRoutes: ', inventory.areas[foundMatchingAreaIndex].invRoutes);
            // console.log('inventory.areas[foundMatchingAreaIndex].invRoutes.invLength: ', inventory.areas[foundMatchingAreaIndex].invRoutes.invLength);
        } else {
            console.log('No area matching route: ', route);
        };
    });
    
    return inventory;
};

const processPins = (inventory, sourcePins) => {
    /* Pins */
    sourcePins && sourcePins.forEach(pin => {
        // console.log(' ============================================================== computeInventory() iterating pin.id: ', pin.id);
        // console.log(' ============================================================== computeInventory() iterating pin.invValue: ', pin.invValue);
        inventory.areas.forEach((area, areaIndex) => {
            // console.log(' ------------------------------ computeInventory() iterating area.id: ', area.id);
            
            // Let's find the route for this pin
            let foundMatchingRouteIndex = -1;
            pin.routes.forEach(routeOfThisPin => {
                
                if (!area.routes) {
                    return false;
                };

                // console.log('computeInventory() routeOfThisPin: ', routeOfThisPin);
                
                foundMatchingRouteIndex = area.routes.findIndex(route => route.id === routeOfThisPin);
                
                // console.log('computeInventory() foundMatchingRouteIndex: ', foundMatchingRouteIndex);

                if (foundMatchingRouteIndex >= 0) {
                    // Found a route for this pin
                    const route = inventory.areas[areaIndex].routes[foundMatchingRouteIndex];

                    // Check if we already counted the length of this route
                    if (!route.processed) {
                        // Measure only the inventoried routes
                        console.log('computeInventory() route: ', route);
                        console.log('computeInventory() route.trackLength: ', route.trackLength);
                        inventory.areas[areaIndex].invRoutes.invLength = inventory.areas[areaIndex].invRoutes.invLength + route.trackLength;
                        console.log('computeInventory() inventory.areas[areaIndex].invRoutes.invLength: ', inventory.areas[areaIndex].invRoutes.invLength);
                    };

                    // Mark this route as processed
                    route.processed = true;

                    
                    // Check if we don't have invPins, we should create it because this is definitely a route that needs to be populateds
                    if (!route.invPins) {
                        route.invPins = {};
                    };
                    
                    // Check if invPins are not populated or the timestamps are too old 
                    // console.log('computeInventory() route: ', route);
                    if (!route.pins) {
                        route.pins = [];
                    };

                    // console.log('============================================================== computeInventory() pin.id is being pushed: ', pin.id);
                    route.pins.push(pin);


                    const lastUpdatedPinInRoute = findLastUpdatedPinInRoute(sourcePins, routeOfThisPin);

                    // Measure all routes
                    // inventory.areas[areaIndex].invRoutes.totalLength = inventory.areas[areaIndex].invRoutes.totalLength + route.trackLength;

                    /* Write last updated pin in route */
                    if (lastUpdatedPinInRoute 
                        && lastUpdatedPinInRoute.lastEditTime 
                        && route.invPins
                        && (!route.invPins.lastEditTime 
                            || lastUpdatedPinInRoute.lastEditTime >= route.invPins.lastEditTime)) 
                            {

                        // .invValue = .invValue + 1 || 1,
                        route.invPins.invValue = lastUpdatedPinInRoute.invValue || 0;
                        route.invPins.lastEditTime = lastUpdatedPinInRoute.lastEditTime;
                        route.invPins.lastEditBy = lastUpdatedPinInRoute.lastEditBy;
                        route.invPins.lastInvRoute = lastUpdatedPinInRoute.lastInvRoute;
                        route.invPins.lastInvPin = lastUpdatedPinInRoute.id;

                        /*
                        // TEMP
                        if (pin.id === 'LT02.07') {
                            // console.log('pin.id: ', pin.id);
                            // console.log('lastUpdatedPinInRoute.id: ', lastUpdatedPinInRoute.id);
                            // console.log('foundMatchingRouteIndex: ', foundMatchingRouteIndex);
                            // console.log('routeOfThisPin: ', routeOfThisPin);
                            // console.log('area: ', area);
                            // console.log('areaIndex: ', areaIndex);
                            // console.log('lastUpdatedPinInRoute: ', lastUpdatedPinInRoute);
                            // console.log('.......................lastUpdatedPinInRoute.invValue: ', lastUpdatedPinInRoute.invValue);
                            // console.log('.......................lastUpdatedPinInRoute.lastEditTime: ', lastUpdatedPinInRoute.lastEditTime);
                            // console.log('.......................lastUpdatedPinInRoute.lastEditBy: ', lastUpdatedPinInRoute.lastEditBy);
                            // console.log('route.invPins: ', route.invPins);
                        }
                        */
                    } else {
                        // Nothing
                        /*
                        // console.log('pin.id: ', pin.id);
                        // console.log('lastUpdatedPinInRoute: ', lastUpdatedPinInRoute);
                        // console.log('lastUpdatedPinInRoute.lastEditTime: ', lastUpdatedPinInRoute.lastEditTime);
                        // console.log('area: ', area);
                        // console.log('area.invRoutes: ', area.invRoutes);
                        // console.log('route.invPins: ', route.invPins);
                        // console.log('route.invPins.lastEditTime: ', route.invPins.lastEditTime);
                        // console.log(' ??????? Exited lastUpdatedPinInRoute: ', lastUpdatedPinInRoute);
                        */
                    };

                    // console.log('Final route: ', route);
                    console.log('Final route.id: ', route.id);
                    // console.log('route.trackLength: ', route.trackLength);
                    // console.log('Final inventory.areas[areaIndex].invRoutes: ', inventory.areas[areaIndex].invRoutes);
                    // console.log('inventory.areas[areaIndex].invRoutes.invLength: ', inventory.areas[areaIndex].invRoutes.invLength);
                };
            });

            // This area has no invRoutes, no need to continue populating it (there are no pins or routes here)
            /*
            if (!inventory.areas[areaIndex].invRoutes) {
                return false;
            };
            */

            // console.log('computeInventory() inventory: ', inventory);
            // console.log('computeInventory() area.id: ', area.id);
            // console.log('computeInventory() pin.routes: ', pin.routes);
            // console.log('computeInventory() inventory.areas[' + areaIndex + '].routes: ', inventory.areas[areaIndex].routes);
            // console.log('computeInventory() foundMatchingRouteIndex: ', foundMatchingRouteIndex);
            
            if (foundMatchingRouteIndex < 0) {
                return false;
            }
            
            let lastUpdatedRouteInArea = null;
            if (inventory.areas[areaIndex].routes) {
                lastUpdatedRouteInArea = findLastUpdatedRouteInArea(inventory.areas[areaIndex].routes, area.id);
            };
            // console.log('computeInventory() lastUpdatedRouteInArea: ', lastUpdatedRouteInArea);
            // console.log('computeInventory() lastUpdatedRouteInArea.lastEditTime: ', lastUpdatedRouteInArea.invRoutes.lastEditTime);
            // console.log('computeInventory() area.invRoutes: ', area.invRoutes);
            // console.log('computeInventory() area.invRoutes.lastEditTime: ', area.invRoutes.lastEditTime);
            
            // Check if we do have invRoutes but not populated or it has old timestamps.
            if (lastUpdatedRouteInArea 
                && lastUpdatedRouteInArea.invPins 
                && lastUpdatedRouteInArea.invPins.lastEditTime 
                && area.invRoutes 
                && inventory.areas[areaIndex].invRoutes
                && (!inventory.areas[areaIndex].invRoutes.lastEditTime
                    || lastUpdatedRouteInArea.invPins.lastEditTime >= inventory.areas[areaIndex].invRoutes.lastEditTime)) 
            {
                // console.log('lastUpdatedRouteInArea entered');
                inventory.areas[areaIndex].invRoutes.invValue = lastUpdatedRouteInArea.invPins.invValue || 0;
                inventory.areas[areaIndex].invRoutes.lastEditTime = lastUpdatedRouteInArea.invPins.lastEditTime;
                inventory.areas[areaIndex].invRoutes.lastEditBy = lastUpdatedRouteInArea.invPins.lastEditBy;
                inventory.areas[areaIndex].invRoutes.lastInvArea = lastUpdatedRouteInArea.invPins.lastInvArea;
                inventory.areas[areaIndex].invRoutes.lastInvPin = lastUpdatedRouteInArea.invPins.lastInvPin;
                inventory.areas[areaIndex].invRoutes.lastInvRoute = lastUpdatedRouteInArea.id;
                /*
                if (area.id === 'VRA') {

                    // console.log('ENTER inventory.areas[areaIndex].routes: ', inventory.areas[areaIndex].routes);
                    // console.log('ENTER area.id: ', area.id);
                
                    // console.log('ENTER lastUpdatedRouteInArea: ', lastUpdatedRouteInArea);
                    // console.log('ENTER lastUpdatedRouteInArea.invRoutes: ', lastUpdatedRouteInArea.invPins);
                    // console.log('ENTER lastUpdatedRouteInArea.invRoutes.lastEditTime: ', lastUpdatedRouteInArea.invPins.lastEditTime);
                    // console.log('ENTER area.invRoutes: ', area.invRoutes);
                    // console.log('ENTER area.invRoutes.lastEditTime: ', area.invRoutes.lastEditTime);
                    // console.log('ENTER inventory.areas[areaIndex].invRoutes: ', inventory.areas[areaIndex].invRoutes);
                    // console.log('ENTER inventory.areas[areaIndex].invRoutes.lastEditTime: ', inventory.areas[areaIndex].invRoutes.lastEditTime);
                    // console.log(' !!!!!!!!!!!!!!! ENTER !!!!!!!!!!!!!');

                };
                */
            } else {
                // Nothing
                /*
                if (area.id === 'VRA') {
                    // console.log('EXIT inventory.areas[areaIndex].routes: ', inventory.areas[areaIndex].routes);
                    // console.log('EXIT area.id: ', area.id);
                
                    // console.log('EXIT lastUpdatedRouteInArea: ', lastUpdatedRouteInArea);
                    // console.log('EXIT lastUpdatedRouteInArea.invRoutes: ', lastUpdatedRouteInArea.invPins);
                    // console.log('EXIT lastUpdatedRouteInArea.invRoutes.lastEditTime: ', lastUpdatedRouteInArea.invPins.lastEditTime);
                    // console.log('EXIT area.invRoutes: ', area.invRoutes);
                    // console.log('EXIT area.invRoutes.lastEditTime: ', area.invRoutes.lastEditTime);
                    // console.log('EXIT inventory.areas[areaIndex].invRoutes: ', inventory.areas[areaIndex].invRoutes);
                    // console.log('EXIT inventory.areas[areaIndex].invRoutes.lastEditTime: ', inventory.areas[areaIndex].invRoutes.lastEditTime);
                    // console.log(' ??????????? Exit ???????????');
                };
                */

                inventory.areas[areaIndex].invRoutes = {
                    invValue: 0,
                    totalLength: 0,
                    invLength: 0,
                    lastEditTime: '',
                    lastEditBy: null,
                    lastInvRoute: null,
                    lastInvPin: null,
                }
            };
        });
    });

    return inventory;
}

const computeInventory = function(sourceAreas, sourceRoutes, sourcePins) {
    /* Structure example - DO NOT DELETE */
    /*
    let inventory = {
        invAreas: {
            invLength: 0,
            totalLength: 0,
            invAreasValue: 0,
            invRoutesValue: 0,
            lastEditTime: null,
            lastEditBy: null,
            lastInvArea: null,
            lastInvRoute: null,
            lastInvPin: null,
        },
        areas: [
            {
                id: '',
                ...
                invRoutes: {
                    invLength: 0,
                    totalLength: 0,
                    invValue: 0,
                    lastEditTime: null,
                    lastEditBy: null,
                    lastInvRoute: null,
                    lastInvPin: null,
                },
                routes: [
                    {
                        id: '',
                        ...
                        invPins: {
                            invValue: 0,
                            lastEditTime: null,
                            lastEditBy: null,
                            lastInvPin: null,
                        },
                        pins: [
                            {
                                id: null,
                                lastEditTime: '',
                                lastEditBy: '',
                            }
                            ...
                        ],
                    }
                ]
            }
        ]
    }
    */

   // First try from cache
    const cachedInventory = Cached.getCollection('inventory');
    if (cachedInventory && cachedInventory.areas && cachedInventory.areas[0] ) {
        // We found cached data
        return cachedInventory;
    };

    console.warn('computeInventory() Inventory not cached, processing now...');

    /* Areas */
    let inventory = {
        invAreas: {
            totalLength: 0,
            invLength: 0,
            invAreasValue: 0,
            invRoutesValue: 0,
            lastEditTime: null,
            lastEditBy: null,
            lastInvArea: null,
            lastInvRoute: null,
            lastInvPin: null,
        },
        areas: sourceAreas,
    };

    // Routes
    inventory = processRoutes(inventory, sourceRoutes);

    /* Pins */
    inventory = processPins(inventory, sourcePins);

    /*
    // TEST the inventoried routes total lengths
    console.log('-----------------------------');
    let routesInvLen = 0;
    inventory.areas.forEach((area, areaIndex) => {
        if (!area.routes) return false;
        area.routes.forEach((route, routeIndex) => {
            if (route.pins) {
                console.log('computeInventory() route.id: ', route.id);
                console.log('computeInventory() route.trackLength: ', route.trackLength);
                routesInvLen = routesInvLen + route.trackLength;
            }
        });
    });
    console.log('-----------------------------');
    console.log('computeInventory() routesInvLen: ', routesInvLen);
    */
   
    /* Final inventory processing */
    if (sourceAreas) {
        const lastUpdatesOnArea = findLastUpdatedArea(sourceAreas);
        inventory.invAreas = {
            id: lastUpdatesOnArea.id,
            title: lastUpdatesOnArea.title,
            totalLength: processedTracks.totalLength, // This is already computed in processedTracks
            invLength: lastUpdatesOnArea.invRoutes.invLength,
            invAreasValue: lastUpdatesOnArea.invRoutes.invAreasValue,
            invRoutesValue: lastUpdatesOnArea.invRoutes.invRoutesValue,
            lastEditTime: lastUpdatesOnArea.invRoutes.lastEditTime,
            lastEditBy: lastUpdatesOnArea.invRoutes.lastEditBy,
            lastInvArea: lastUpdatesOnArea.invRoutes.lastInvArea,
            lastInvRoute: lastUpdatesOnArea.invRoutes.lastInvRoute,
            lastInvPin: lastUpdatesOnArea.invRoutes.lastInvPin,
            // routes: lastUpdatesOnArea.routes, // It exists, but it's not needed for now
        };
    };
    
    // Write inventory to cache
    Cached.set('inventory', inventory);
    
    return inventory;
}

const findUserByEmail = function(usersArray, emailToFind) {
    return usersArray.find(user => {
        return user.email === emailToFind;
    });
};

const findTrackByCode = (trackCodeToFind) => {
    // console.log('HandleMaps findCurrentTrackByCode() trackCodeToFind:', trackCodeToFind);
    // console.log('HandleMaps findCurrentTrackByCode() processedTracks:', processedTracks);
   return processedTracks.find((track) => trackCodeToFind === track.name)
}

export { 
    computeInventory, 
    findLastUpdatedArea, 
    findLastUpdatedPinInRoute, 
    findLastUpdatedRouteInArea, 
    findTrackByCode, 
    findUserByEmail,
};