import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
// import windowSize from 'react-window-size';

import Aux from "../../../../../../hoc/_Aux";
import NavIcon from "./../NavIcon";
import NavBadge from "./../NavBadge";
import * as actionTypes from "../../../../../../store/actions";

function NavItem(props) {

    let itemTitle = props.item.title;
    let itemTarget = '';
    let subContent;
    let mainContent = '';

    if (props.item.icon) {
        itemTitle = <span className="pcoded-mtext">{props.item.title}</span>;
    }

    if (props.item.target) {
        itemTarget = '_blank';
    }

    if(props.item.external) {
        subContent = (
            <a href={props.item.url} target='_blank' rel='noopener noreferrer'>
                <NavIcon items={props.item}/>
                {itemTitle}
                <NavBadge layout={props.layout} items={props.item}/>
            </a>
        );
    } else {
        subContent = (
            <NavLink to={props.item.url} className="nav-link" target={itemTarget}>
                <NavIcon items={props.item}/>
                {itemTitle}
                <NavBadge layout={props.layout} items={props.item}/>
            </NavLink>
        );
    }
    if (props.layout === 'horizontal') {
        mainContent = (
            <li onClick={props.onItemLeave}>{subContent}</li>
        );
    } else {
        if (props.windowWidth < 992) {
            mainContent = (
                <li className={props.item.classes} onClick={props.onItemClick}>{subContent}</li>
            );
        } else {
            mainContent = (
                <li className={props.item.classes}>{subContent}</li>
            );
        }
    }

    return (
        <Aux>
            {mainContent}
        </Aux>
    )
}

const mapStateToProps = state => {
    return {
        layout: state.layout,
        collapseMenu: state.collapseMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onItemClick: () => dispatch({type: actionTypes.COLLAPSE_MENU}),
        onItemLeave: () => dispatch({type: actionTypes.NAV_CONTENT_LEAVE})
    }
};

// export default connect(mapStateToProps, mapDispatchToProps) (windowSize(NavItem));
export default connect(mapStateToProps, mapDispatchToProps) (NavItem);
