import React from 'react';
// import Context from "./Context";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

class Cached extends React.PureComponent{
    constructor(props) {
        super(props);
        
        this.state = {

        }

        this.mem = {
            areas: [], 
            dbStatuses: [],
            pins: [],
            pinTypes: [],
            routes: [],
            statuses: [],
            users: [],
            userRoles: [],
            inventory: [],
        }
    }

    set(collection, data, collectionId) {
        if (!this.mem[collection]) {
            toast.error('ERR11: Cached set() - collection does not exist: ' + collection.toString(), {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            return [];
        }

        if (collectionId) {
            this.mem[collection][collectionId] = data;
        } else {
            this.mem[collection] = data;
        };

        return data;
    }

    getCollection (collection, collectionId) {
        return collectionId ? (this.mem[collection] ? this.mem[collection][collectionId] : []) : this.mem[collection];
    }

    getItem (item, collection, collectionId) {
        return collectionId ? (this.mem[collection] && this.mem[collection][collectionId] ? this.mem[collection][collectionId][item] : []) : this.mem[collection][item];
    }

}

export default new Cached();