import React, { useState } from 'react';
import {connect} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import windowSize from 'react-window-size';

import Aux from "../../../../../hoc/_Aux";
import NavGroup from './NavGroup';
import DEMO from "../../../../../store/constant";
import * as actionTypes from "../../../../../store/actions";

function NavContent (props) {
    const [scrollWidth, setScrollWidth] = useState(0);
    const [prevDisable, setPrevDisable] = useState(true);
    const [nextDisable, setNextDisable] = useState(false);

    const scrollPrevHandler = () => {
        const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;

        let localScrollWidth = scrollWidth - wrapperWidth;
        if (localScrollWidth < 0) {
            setScrollWidth(0);
            setPrevDisable(true);
            setNextDisable(false);
        } else {
            setScrollWidth(localScrollWidth);
            setPrevDisable(false);
        }
    };

    const scrollNextHandler = () => {
        const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;
        const contentWidth = document.getElementById('sidenav-horizontal').clientWidth;

        let localScrollWidth = scrollWidth + (wrapperWidth - 80);
        if (localScrollWidth > (contentWidth - wrapperWidth)) {
            localScrollWidth = contentWidth - wrapperWidth + 80;
            setScrollWidth(localScrollWidth);
            setPrevDisable(false);
            setNextDisable(true);
        } else {
            setScrollWidth(localScrollWidth);
            setPrevDisable(false);
        }
    };

    // render() {
        const navItems = props.navigation.map(item => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup layout={props.layout} key={item.id} group={item}/>;
                    default:
                        return false;
                }
            }
        );

        let mainContent = '';
        if (props.layout === 'horizontal') {
            let prevClass = ['sidenav-horizontal-prev'];
            if (prevDisable) {
                prevClass = [...prevClass, 'disabled'];
            }
            let nextClass = ['sidenav-horizontal-next'];
            if (nextDisable) {
                nextClass = [...nextClass, 'disabled'];
            }

            mainContent = (
                <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
                    <a href={DEMO.BLANK_LINK} className={prevClass.join(' ')} onClick={scrollPrevHandler}><span/></a>
                    <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper">
                        <ul id="sidenav-horizontal" className="nav pcoded-inner-navbar sidenav-inner" onMouseLeave={props.onNavContentLeave} style={{marginLeft: '-' + scrollWidth + 'px'}}>
                            {navItems}
                        </ul>
                    </div>
                    <a href={DEMO.BLANK_LINK} className={nextClass.join(' ')} onClick={scrollNextHandler}><span/></a>
                </div>
            );
        } else {
            mainContent = (
                <div className="navbar-content datta-scroll">
                    <PerfectScrollbar>
                        <ul className="nav pcoded-inner-navbar">
                            {navItems}
                        </ul>
                    </PerfectScrollbar>
                </div>
            );
        }

        return (
            <Aux>
                {mainContent}
            </Aux>
        );
    // }
}

const mapStateToProps = state => {
    return {
        layout: state.layout,
        collapseMenu: state.collapseMenu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onNavContentLeave: () => dispatch({type: actionTypes.NAV_CONTENT_LEAVE}),
    }
};

// export default connect(mapStateToProps, mapDispatchToProps) (windowSize(NavContent));
export default connect(mapStateToProps, mapDispatchToProps) (NavContent);
