import React, { Suspense, useContext, useEffect, useState } from 'react';
import {Route, Routes} from 'react-router-dom';
import {connect} from 'react-redux';

import Navigation from './Navigation';
import NavBar from './NavBar';
import Breadcrumb from './Breadcrumb';
import Loader from "../Loader";
import menuRoutes from "../../../routes";
import Aux from "../../../hoc/_Aux";
import * as actionTypes from "../../../store/actions";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Context from '../../libs/Context';
import Cached from "../../libs/Cached";
import {
    computeInventory,
} from "../../libs/DataUtils";
import FBH from "../../components/Firebase";

import './app.scss';

toast.configure();

function AdminLayout(props) {
    // const handle = useFullScreenHandle();
    const appContext = useContext(Context);
    const collections = ['areas', 'dbStatuses', 'pins', 'pinTypes', 'routes', 'statuses', 'users', 'userRoles'];
    const [_isLoaded, set_isLoaded] = useState({ isDataLoaded: false, isInventoryComputed: false });
    const [stateData, setStateData] = useState({
        areas: [],
        dbStatuses: [],
        pins: [],
        pinTypes: [],
        routes: [],
        statuses: [],
        users: [],
        userRoles: null,
        inventory: null,
    });

    // console.log('AdminLayout initializing stateData with: ', stateData);

    // set_isLoaded({ 
            // isDataLoaded: true, 
            // isInventoryComputed: true,
    // });

    /*
    const fullScreenExitHandler = () => {
        if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            props.onFullScreenExit();
            handle.exit();
        }
    };
    */
   
    // console.log('AdminLayout index menuRoutes: ', menuRoutes);
    const menu = menuRoutes.map((menuRoute, index) => {
        // console.log('AdminLayout menuRoutes.map() menuRoute: ', menuRoute);
        // console.log('AdminLayout menuRoutes.map() index: ', index);
        /*
        // console.log('AdminLayout menuRoutes.map() data: ', {
            areas: areas,
            dbStatuses: dbStatuses,
            pins: pins,
            pinTypes: pinTypes,
            routes: routes,
            statuses: statuses,
            users: users,
            userRoles: userRoles,
            inventory: inventory,
        });
        */

        return (menuRoute.component) ? (
<           Route
                key={index}
                path={menuRoute.path}
                exact={menuRoute.exact}
                name={menuRoute.name}
                element={<menuRoute.component data={stateData} 
                />}
            />
        ) : (null);
    });

   
    const callbackSetState = (freshData) => {
        // console.log('AdminLayout callbackSetState() response: ', response);
        // console.log('AdminLayout callbackSetState() response.length: ', response.length);

        /* Update the cache */
        Cached.set('routes', freshData);

        /* Update the state */
        // updateCollection('routes', freshData);
        // setInventory(cI);
        
        stateData['routes'] = freshData;
        const cI = computeInventory(stateData['areas'], stateData['routes'], stateData['pins']);
        stateData['inventory'] = cI;

        // console.log('AdminLayout callbackSetState() for routes - cI: ', cI);
        // console.log('AdminLayout callbackSetState() stateData[inventory]: ', stateData['inventory']);

        if (!_isLoaded.isDataLoaded) { 
            set_isLoaded({ 
                isDataLoaded: true, 
                isInventoryComputed: true,
            });
        };
        // console.log('AdminLayout callbackSetState() 5 _isLoaded was set to: ', _isLoaded);
    }

    useEffect((prevProps, nextProps) => {
        // console.log('AdminLayout useEffect() prevProps: ', prevProps);
        // console.log('AdminLayout useEffect() nextProps: ', nextProps);
        
        if (props.windowWidth > 992 && props.windowWidth <= 1024 && props.layout !== 'horizontal') {
            props.onComponentWillMount();
        };

        !_isLoaded && loadData();
    }, [_isLoaded])

    const mobileOutClickHandler = () => {
        if (props.windowWidth < 992 && props.collapseMenu) {
            props.onComponentWillMount();
        }
    };

    /*
    const updateCollection = function(collection, data) {
        // ['areas', 'dbStatuses', 'pins', 'pinTypes', 'routes', 'statuses', 'users', 'userRoles'];
        switch(collection) {
            case 'areas':
                setAreas(data);
                break;
            case 'dbStatuses':
                setDbStatuses(data);
                break;
            case 'pins':
                setPins(data);
                break;
            case 'pinTypes':
                setPinTypes(data);
                break;
            case 'statuses':
                setStatuses(data);
                break;
            case 'users':
                setUsers(data);
                break;
            case 'userRoles':
                setUserRoles(data);
                break;
            default:
              // code block
        };
        return collection;
    };
    */

    const loadData = () => {
        if (appContext.user) {
            // console.log('AdminLayout loadData() we have appContext.user: ', appContext.user);
            // console.log('AdminLayout loadData() signInResult=isUserSignedIn() FBH.user: ', FBH.user);
            
            // console.log('AdminLayout loadData() _isLoaded: ', _isLoaded);
            // console.log('AdminLayout loadData() stateData: ', stateData);
            // console.log('AdminLayout loadData() inventory: ', inventory);
            if (_isLoaded.isDataLoaded) { 
                return; 
            };
            
            collections.forEach((collection) => {
                /* First try in cache */
                const cachedData = Cached.getCollection(collection);
                // console.log('AdminLayout loadData() Lets see if we have cached data for collection ' + collection + ': ', cachedData);
                if (cachedData && cachedData.length > 0 ) {
                    /* We found cached data */
                    // console.log('AdminLayout loadData() we have cachedData: ', cachedData);
                    
                    /*
                    // TEMP
                    if (collection === 'users') {
                        // console.log('AdminLayout loadData() we have cachedData: ', cachedData);
                    };
                    */

                   
                   // This HAS to be rewritten
                   stateData[collection] = cachedData;
                   // console.log('AdminLayout loadData() stateData[' + collection + ']: ', stateData[collection]);
                   // updateCollection(collection, cachedData);
                   // setInventory(cI);
                   
                   // const cI = computeInventory(areas, routes, pins);
                   const cI = computeInventory(stateData['areas'], stateData['routes'], stateData['pins']);
                   stateData['inventory'] = cI;
                   // console.log('AdminLayout loadData() cI: ', cI);
                   // console.log('AdminLayout loadData() stateData[inventory]: ', stateData['inventory']);

                   if (!_isLoaded.isDataLoaded) { 
                    set_isLoaded({ 
                        isDataLoaded: true, 
                        isInventoryComputed: true,
                    });
                }

                   // console.log('AdminLayout loadData() 1 _isLoaded was set to: ', _isLoaded);
                   
                   // return Promise.resolve({ success: true, [collection]: cachedData});
                } else {
                    /* No cached data, get fresh data from DB */
                    // TEMP
                    // console.log('No cache for collection: ', collection);
                    // console.log('We are getting fresh data');
                    
                    if (collection !== 'routes') {
                        // set_isLoaded({ 
                            // isDataLoaded: false, 
                            // isInventoryComputed: false,
                        // });
                        // console.log('AdminLayout loadData() 2 _isLoaded was set to: ', _isLoaded);

                        // return 
                        FBH.getAllDocsFromDb(collection)
                        .then(freshData => {
                            // console.log('We got freshData: ', freshData);
                            /*
                            // TEMP
                            if (collection === 'users') {
                            // console.log('No cache for collection: ', collection);
                            // console.log('We are getting freshData: ', freshData);
                            };
                            */


                            /* Update the cache */
                            Cached.set(collection, freshData);

                            /* Update the state */
                            // updateCollection(collection, freshData);
                            stateData[collection] = freshData;

                            if (!_isLoaded.isDataLoaded) {
                                set_isLoaded({ 
                                    isDataLoaded: true, 
                                    isInventoryComputed: true,
                                });
                            };
                            // console.log('AdminLayout loadData() 3 _isLoaded was set to: ', _isLoaded);
                        });                    
                    } else {
                        // return 
                        FBH.getRoutes(callbackSetState);
                    }
                }
            });
            
        } else {
            // set_isLoaded(false);
            // console.log('AdminLayout loadData() 4 _isLoaded was set to: ', _isLoaded);
            toast.error('ERR10: Dashboard loadData() FBH User does not exist', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            FBH.isUserSignedIn(appContext);
            console.error('ERR10: Dashboard loadData() appContext.user does not exist: ');
        }
    }

    // console.log('************************************************* calling loadData(), having props: ', props);
    loadData();

    return(
        <Aux>
            <Navigation />
            <NavBar />
            <div className="pcoded-main-container" onClick={() => mobileOutClickHandler}>
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <Breadcrumb />
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <Suspense fallback={<Loader/>}>
                                        <Routes>
                                            {menu}
                                            <Route path={props.defaultPath} />
                                        </Routes>
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    );
}

const mapStateToProps = state => {
    return {
        defaultPath: state.defaultPath,
        isFullScreen: state.isFullScreen,
        collapseMenu: state.collapseMenu,
        configBlock: state.configBlock,
        layout: state.layout
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // onFullScreenExit: () => dispatch({type: actionTypes.FULL_SCREEN_EXIT}),
        onComponentWillMount: () => dispatch({type: actionTypes.COLLAPSE_MENU})
    }
};

// export default connect(mapStateToProps, mapDispatchToProps) (windowSize(AdminLayout()));
export default connect(mapStateToProps, mapDispatchToProps) (AdminLayout);