// https://blog.devgenius.io/react-native-state-management-with-context-api-61f63f5b099

import React, {Component} from 'react';
import Context from "./Context";

export default class GlobalState extends Component{
    state = {
        user: null,
        routes: [],
        currentRoute: '',
        currentPin: '',
        pins: [],
        appStarted: false,
        isLoggedIn: false,
    }

    toggleShowStartSplash = (toggle) => {
        this.setState({
            appStarted: toggle
        })
    }

    toggleLoggedIn = (toggle) => {
        this.setState({
            isLoggedIn: toggle
        })
    }

    setUser = (user) => {
        let uinfo = {};
        if (user && user.additionalUserInfo && user.user) {
            uinfo = user.user;
            uinfo.providerId = user.additionalUserInfo.providerId;
        } else if(user && user.providerData) {
            uinfo = user.providerData[0];
        } else {
            uinfo = user;
        }

        this.setState({
            user: uinfo,
            isLoggedIn: user ? true : false,
        })
    }

    setNearbyRoutes = (routesArray) => {
        this.setState({
            nearbyRoutes: routesArray,
        })
    }

    setCurrentRoute = (currentRoute) => {
        this.setState({
            currentRoute: currentRoute
        })
    }

    setCurrentPin = (currentPin) => {
        this.setState({
            currentPin: currentPin
        })
    }


    render(){
        return (
            <Context.Provider 
                value={{
                    // Props / toggles
                    user: this.state.user,
                    appStarted: this.state.appStarted,
                    isLoggedIn: this.state.isLoggedIn,
                    nearbyRoutes: this.state.nearbyRoutes,
                    currentRoute: this.state.currentRoute,
                    currentPin: this.state.currentPin,
                    // Methods
                    toggleShowStartSplash: this.toggleShowStartSplash,
                    toggleLoggedIn: this.toggleLoggedIn,
                    setUser: this.setUser,
                    setNearbyRoutes: this.setNearbyRoutes,
                    setCurrentRoute: this.setCurrentRoute,
                    setCurrentPin: this.setCurrentPin,
                }}
            >
                {this.props.children}
            </Context.Provider>
        );
    }
}