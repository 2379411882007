import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, where} from "firebase/firestore";
import Context from "../libs/Context";
import { 
    getAuth, 
    GoogleAuthProvider, 
    onAuthStateChanged, 
    signInAnonymously, 
    signInWithPopup 
} from "firebase/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firebaseConfig, GKeys } from './Settings';

toast.configure();

const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

/*
const app = initializeApp({
  // Your firebase configuration object
});
*/
// Initialize Firebase with a default Firebase project
const firebaseApp = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(GKeys.reCaptcha3.key),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});


const db = getFirestore(firebaseApp);

class FirebaseHelper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.unsubscribeListeners = [];
        this.getRoutes = this.getRoutes.bind(this);
        this.getPins = this.getPins.bind(this);
       
        /* Signin */
        this.onAuthStateChanged = onAuthStateChanged;
        this.googleProvider = new GoogleAuthProvider();
        this.analytics = getAnalytics(firebaseApp);
        this.auth = getAuth();
        this.auth.languageCode = 'ro';

        // console.log('FirebaseHelper constructor() this.context: ', this.context);
        // console.log('FirebaseHelper constructor() this.auth: ', this.auth);
        // console.log('FirebaseHelper constructor() this.auth.length: ', this.auth.length);

        this.isUserSignedIn = this.isUserSignedIn.bind(this);
        this.signInWithGoogle = this.signInWithGoogle.bind(this);
        this.handleOnAuthStateChanged = this.handleOnAuthStateChanged.bind(this);
    }

    static contextType = Context;
    
    componentWillUnmount() {
        this.unsubscribeListeners.map(unsubscribe => typeof unsubscribe == 'function' ? unsubscribe() : false);
    }
    
    isUserSignedIn = async (context) => {
        // console.log('FirebaseHelper isUserSignedIn() this.context: ', this.context);
        // console.log('FirebaseHelper isUserSignedIn() context: ', context);
        const user = this.auth.currentUser;

        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            /*
            toast.warn('isUserSignedIn() user ', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            */
            
            context.setUser(user);
            this.writeUserLoggedInToDb(user);
            // console.log('FirebaseHelper isUserSignedIn() context.user: ', context.user);
            // return <Navigate to="/dashboard" replace={true}  />
        } else {
            // User is signed out
            /// console.warn('FirebaseHelper isUserSignedIn() signed out');
            
            if (context) {
                context.setUser(null);
            } else if (this.context) {
                this.context.setUser(null);
            };
            // return <Navigate to={'/'} />
        }
        return Promise.resolve({success: true});
    } 

    writeUserLoggedInToDb = async (user) => {
        if (!user || !user.uid) {
            console.log('ERR12: user.uid is missing', user);
            toast.error('ERR12: user.uid is missing', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            return Promise.resolve({success: false, message: 'ERR12: user.uid is missing'});
        };

        const usersCollectionRef = collection(db, "users");
        const usersQuery = query(usersCollectionRef, where('uid', 'array-contains', "==", user.uid));
        return getDocs(usersQuery).then((records) => {
            // If the doc exist, it will be updated
            // If the doc doesn't exist, it will be created 
            const dataToWrite = {
                uid: user.uid,
                displayName: user.displayName,
                emailVerified: user.emailVerified,
                isAnonymous: user.isAnonymous,
                providerId: user.providerId,
                providerIdAuth: user.providerData[0].providerId,
                photoURL: user.photoURL,
                phoneNumber: user.phoneNumber,
                email: user.email,
                createdAt: user.metadata.createdAt,
                creationTime: user.metadata.creationTime,
                lastLoginAt: user.metadata.lastLoginAt,
                lastSignInTime: user.metadata.lastSignInTime,
            };

            
            toast.success('Autentificare cu userul ' + user.displayName, {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            
            return setDoc(doc(usersCollectionRef, user.email), dataToWrite).then(() => user.id);
            // return Promise.resolve({success: true});
        }).catch(error => {
            console.log('ERR07: getDocs(user) error: ', error);
            toast.error('ERR07: getDocs(user) error', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            return Promise.resolve({success: false, message: error.message});
        });
    }
    
    signInWithGoogle = async (context) => {
        try {
            const res = await signInWithPopup(this.auth, this.googleProvider);
            context.setUser(res.user);
            if (res.user && res.user.uid) {
                return this.writeUserLoggedInToDb(res.user);
            } else {
                toast.error('ERR09: signInWithGoogle() signInWithPopup() retrieving res.user failed', {
                    position: 'bottom-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    thene: 'colored',
                });
                return Promise.resolve({success: false, message: 'ignInWithGoogle() signInWithPopup() retrieving res.user failed'});
            }
        } catch (error) {
            console.log('ERR08: signInWithGoogle() error: ', error);
            toast.error('ERR08: signInWithGoogle() failure: ' + error.message, {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            // const errorCode = error.code;
            // const errorMessage = error.message;
            return Promise.resolve({success: false, message: error.message});
        }
    };

    signInAnonymously = async () => {
        return signInAnonymously(this.auth)
        .then(() => {
            // Signed in..
            toast.success('signInAnonymously()  Autentificare cu succes', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            return {success: true};
        }).catch((error) => {
            toast.error('ERR06 : signInAnonymously() failure: ' + error.message, {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            // const errorCode = error.code;
            // const errorMessage = error.message;
            return {success: false, message: error.message}
            // ...
        });
    }

    handleOnAuthStateChanged =(context) => {
        // console.log('FirebaseHelper handleOnAuthStateChanged() this.context: ', this.context);
        // console.log('FirebaseHelper handleOnAuthStateChanged() context: ', context);
        return onAuthStateChanged(this.auth, (user) => {
            // console.log('FirebaseHelper onAuthStateChanged() started');
            // console.log('FirebaseHelper onAuthStateChanged() user: ', user);
            this.isUserSignedIn(context);
        })
    }
    
    async getAllDocsFromDb(collectionName) {
        const q = query(collection(db, collectionName));
        let genericCollection = [];
        // console.log('getAllDocsFromDb() q: ', q);
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                genericCollection.push(doc.data());
                // TEMP
                if (collectionName === 'users') {
                    // console.log('FBH getAllDocsFromDb() doc.id:' + doc.id, " => ", doc.data());
                    // console.log('FBH getAllDocsFromDb() genericCollection: ', genericCollection);
                }
            });
        })
        this.unsubscribeListeners.push(unsubscribe);
        return genericCollection;

        /*
        return getDocs(q).then((records) => {
            // console.log('FirebaseHelper getAllDocsFromDb() records:', records );
            // console.log('getAllDocsFromDb() querySnapshot: ', querySnapshot);
            let collection = [];
            
            records.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log('getAllDocsFromDb() doc.id:' + doc.id, " => ", doc.data());
                collection.push(doc.data());
            });

            return collection;
        });
        */
    }

    async getReferencedDocFromDb(itemRef) {
        // const docRef = doc(db, "statuses", "SF");
        const docSnap = await getDoc(itemRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            // console.log("Document data:", data);
            return data;
        } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
            return null;
        }
    }

    
    resolveRouteSubpromises = (route, rowPromises) => {
        // console.log('resolveRouteSubpromises() rowPromises:', rowPromises);
        return Promise.all(rowPromises).then((result) => {
            // console.log('resolveRouteSubpromises() promisesForAllRows rowPromises result: ', result);
            // Add flatten info for routes

            route.areaFlat = result[0];
            route.statusFlat = result[1];
            route.dbStatusFlat = result[2];

            return route;
        }).catch((error) => {
            toast.error('ERR04 : resolve failure: ' + error.message, {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            // console.log('resolveRouteSubpromises() promisesForAllRows error: ', error);
            // alert('Promises error');
            return route;
        });

        // return route;
    }

    resolvePinSubpromises = (pin, rowPromises) => {
        // console.log('resolvePinSubpromises() rowPromises:', rowPromises);
        return Promise.all(rowPromises).then((result) => {
            // console.log('resolvePinSubpromises() promisesForAllRows rowPromises result: ', result);
            // Add flatten info for pins

            pin.pinTypeFlat = result[0];
            pin.statusFlat = result[1];
            pin.dbStatusFlat = result[2];

            return pin;
        }).catch((error) => {
            toast.error('ERR05 : resolve failure: ' + error.message, {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                thene: 'colored',
            });
            // console.log('resolvePinSubpromises() promisesForAllRows error: ', error);
            // alert('Promises error');
            return pin;
        });

        // return pin;
    }
    
    async getRoutes(callbackSetState) {
        const q = query(collection(db, "routes"));
        let routes = [];
        // var x = new Promise();
        
        // console.log('getRoutes() q: ', q);
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let route = null;
            routes = new Promise((resolve, reject) => {
                querySnapshot.forEach((doc) => {
                    route = doc.data();
                    // console.log('getRoutes() route:', route);
                    let promisesForThisRow = [];
    
                    promisesForThisRow.push(this.getReferencedDocFromDb(route.area));
                    promisesForThisRow.push(this.getReferencedDocFromDb(route.status));
                    promisesForThisRow.push(this.getReferencedDocFromDb(route.dbStatus));
                    
                    route = this.resolveRouteSubpromises(route, promisesForThisRow);
                    routes.push(route);
                });
                
                // console.log('getRoutes() promise: ', promise);
    
                return Promise.all(routes).then(response => {
                    // console.log('FirebaseHelper getRoutes() response 3:', response);
                    // console.log('FirebaseHelper getRoutes() response.length 3:', response.length);
                    /*
                    this.setState({
                        routes: response,
                    }, () => {
                        // console.log('FirebaseHelper getRoutes() setState then ');
                        return callbackSetState(response);
                    });
                    */

                    callbackSetState(response);

                    return resolve(response);
                });
            });            
        });
        
        this.unsubscribeListeners.push(unsubscribe);
        
        // console.log('FirebaseHelper getRoutes() routes 2:', routes);
        // console.log('FirebaseHelper getRoutes() routes.length 2:', routes.length);
        // console.log('FirebaseHelper getRoutes() unsubscribe:', unsubscribe);
        return routes;
    }
    
    async getPins(row, callbackSetState) {
        // console.log('FirebaseHelper getPins() row:', row);
        if (!row) return [];

        const q = query(collection(db, "pins"), where('routes', 'array-contains', row.idAndCode.id));
        let pins = [];
        // var x = new Promise();
        
        // console.log('FirebaseHelper getPins() q: ', q);
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let pin = null;
            new Promise((resolve, reject) => {
                querySnapshot.forEach((doc) => {
                    pin = doc.data();
                    // console.log('FirebaseHelper getPins() pin:', pin);
                    let promisesForThisRow = [];
    
                    promisesForThisRow.push(this.getReferencedDocFromDb(pin.pinType));
                    promisesForThisRow.push(this.getReferencedDocFromDb(pin.status));
                    promisesForThisRow.push(this.getReferencedDocFromDb(pin.dbStatus));
                    
                    pin = this.resolvePinSubpromises(pin, promisesForThisRow);
                    pins.push(pin);
                });
                
                // console.log('FirebaseHelper getPins() promise: ', promise);
    
                return Promise.all(pins).then(response => {
                    callbackSetState(response);

                    return resolve(response);
                });
            });            
        });
        
        this.unsubscribeListeners.push(unsubscribe);
        
        // console.log('FirebaseHelper getPins() routes 2:', routes);
        // console.log('FirebaseHelper getPins() routes.length 2:', routes.length);
        // console.log('FirebaseHelper getPins() unsubscribe:', unsubscribe);
        return pins;
    }
}

export default new FirebaseHelper();