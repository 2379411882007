import React, { useContext } from 'react';
import Context from "../libs/Context";
import GoogleButton from 'react-google-button';
import FBH from '../components/Firebase';
import '../../assets/scss/style.scss';

function Login() {
    const appContext = useContext(Context);

    // console.log('Login this: ', this);
    return (
        <div className="auth-wrapper">
            <div className="auth-content">
                <div className="auth-bg">
                    <span className="r"/>
                    <span className="r s"/>
                    <span className="r s"/>
                    <span className="r"/>
                </div>
                <div style={{position: 'absolute'}}>
                    <div className="text-center">
                        <GoogleButton
                            type={'light'}
                            onClick={() => { 
                                console.log('Google button clicked, context: ', appContext);
                                return FBH.signInWithGoogle(appContext);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
