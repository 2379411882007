const GKeys = {
    googleMapsURLKeys: { key: 'AIzaSyD31yrP1ItkPk4np9X8iolDdpaQxdhPzG0' },
    reCaptcha3: {key: '6Ldb_b8dAAAAAMNXTA9VUG89npwsTSzjZ_Hi-amV'},
}
const firebaseConfig = {
    apiKey: "AIzaSyCj768MO2SvscBW1-HjsYopaT4sYAvdCX0",
    authDomain: "amc-app-dd92f.firebaseapp.com",
    projectId: "amc-app-dd92f",
    storageBucket: "amc-app-dd92f.appspot.com",
    messagingSenderId: "764004335095",
    appId: "1:764004335095:web:eb651174df248c75aa42f2",
    measurementId: "G-VWDKY8ZGWC",
};

const Settings = {
    misc: { 
        skipLoadingPicturesWhenOffline: true,
        getFlagsFromLocalStorage: true,
        localFlags: {
            'BA01': require('../../assets/images/flags/BA01.png'),
            'BG01': require('../../assets/images/flags/BG01.png'),
            'BR01': require('../../assets/images/flags/BR01.png'),
            'CA01': require('../../assets/images/flags/CA01.png'),
            'CG01': require('../../assets/images/flags/CG01.png'),
            'CR01': require('../../assets/images/flags/CR01.png'),
            'PA01': require('../../assets/images/flags/PA01.png'),
            'PG01': require('../../assets/images/flags/PG01.png'),
            'PR01': require('../../assets/images/flags/PR01.png'),
            'TA01': require('../../assets/images/flags/TA01.png'),
            'TG01': require('../../assets/images/flags/TG01.png'),
            'TR01': require('../../assets/images/flags/TR01.png'),
        },
        flagSigns: {
            'BR': { color: 'red', sign: 'bandă', colorName: 'roșie'},
            'CR': { color: 'red', sign: 'cerc', colorName: 'roșu'},
            'PR': { color: 'red', sign: 'pătrat', colorName: 'roșu'},
            'TR': { color: 'red', sign: 'triunghi', colorName: 'roșu'},

            'BA': { color: 'blue', sign: 'bandă', colorName: 'albastră'},
            'CA': { color: 'blue', sign: 'cerc', colorName: 'albastru'},
            'PA': { color: 'blue', sign: 'pătrat', colorName: 'albastru'},
            'TA': { color: 'blue', sign: 'triunghi', colorName: 'albastru'},

            'BG': { color: 'yellow', sign: 'bandă', colorName: 'galbenă'},
            'CG': { color: 'yellow', sign: 'cerc', colorName: 'galben'},
            'PG': { color: 'yellow', sign: 'pătrat', colorName: 'galben'},
            'TG': { color: 'yellow', sign: 'triunghi', colorName: 'galben'},
        },
    },

    defaults: {
        pinType: 'SS001',
        status: 'S50', // Necunoscut
        dbStatus: 1,
    },
    pins: {
        maxIntersectedRoutes: 5,
    },
    routes: {
        commonRouteWidth: 1,
        currentRouteWidth: 4,
    },
    images: {
        maxNumberOfLocalPictures: 3,
        maxNumberOfOwnPictures: 3,
        maxNumberOfPictures: 7,
    },
    dbStatuses: {
        1: 'active',
        2: 'inactive',
        3: 'deleted',
    },
    geolocation: {
        // Zoom levels for MapView
        // maxZoomLevel: 21,
        // minZoomLevel: 5,
        latitudeDelta: 0.004,
        longitudeDelta: 0.004,
    }
}
export { GKeys, firebaseConfig, Settings };