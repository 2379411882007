import React, { Component, Suspense } from 'react';
import Context from "./libs/Context";
import { Route, Routes } from 'react-router-dom';

import FBH from './components/Firebase';
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";

import AdminLayout from './layout/AdminLayout';
import LoginLayout from './pages/Login';

class App extends Component {
    static contextType = Context;

    componentDidMount() {
        // console.log('App index componentDidMount() this.context: ', this.context);
        FBH.handleOnAuthStateChanged(this.context);
        // FBH.isUserSignedIn(this.context);
    }

    render() {
        // console.log('App index render() this.props: ', this.props);
        // console.log('App index render() routes: ', routes);
        // console.log('App index render() this.context: ', this.context);
        // console.log('App index render() FBH.auth.currentUser: ', FBH.auth.currentUser);
        // console.log('App index render() FBH.auth.currentUser.uid: ', FBH.auth?.currentUser?.uid);
        // console.log('App index render() FBH.auth.currentUser.length: ', FBH.auth?.currentUser?.length);
        //         if (this.state.user) {
            const menu = routes.map((route, index) => {
                // console.log('App index render() route: ', route);
                return (route.component) ?
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        element={<route.component />}
                    />
                :
                null;
            });
            
            // console.log('App index render() menu: ', menu);

        return (
            <LightgalleryProvider
                onBeforeOpen={() => console.info("onBeforeOpen")}
                onAfterOpen={() => console.info("onAfterOpen")}
                onSlideItemLoad={() => console.info("onSlideItemLoad")}
                onBeforeSlide={() => console.info("onBeforeSlide")}
                onAfterSlide={() => console.info("onAfterSlide")}
                onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
                onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
                onDragstart={() => console.info("onDragstart")}
                onDragmove={() => console.info("onDragmove")}
                onDragend={() => console.info("onDragend")}
                onSlideClick={() => console.info("onSlideClick")}
                onBeforeClose={() => console.info("onBeforeClose")}
                onCloseAfter={() => console.info("onCloseAfter")}
            >

                <Aux>
                    <Suspense fallback={<Loader/>}>
                        <ScrollToTop>
                            <Routes>
                                {menu}
                                { (this.context.user && this.context.user.uid) || (FBH.auth.currentUser && FBH.auth.currentUser.uid) ? 
                                    <Route path="*" element={<AdminLayout />}/> 
                                    :
                                    <Route path="*" element={<LoginLayout />} />
                                }
                            </Routes>
                        </ScrollToTop>

                    </Suspense>
                </Aux>
            </LightgalleryProvider>
        );
    }
}

export default App;
