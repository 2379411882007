import { useEffect, useRef } from 'react';
// import {withRouter} from 'react-router-dom';

function ScrollToTop(props) {
    
    let prevPropsRef = useRef();
    useEffect(() => {
        // Update the document title using the browser API
        if (prevPropsRef.current && prevPropsRef.current.location !== props.location) {
            window.scrollTo(0, 0)
        };

        prevPropsRef.current = props;
    });

    return props.children;
}
export default ScrollToTop